import React from 'react';
import { withPreview } from 'gatsby-source-prismic';
import { graphql } from 'gatsby';
import { color } from '../shared/styles';
import Layout from '../components/layout';
import HeroSection from '../components/HeroSection';
import RelatedProjects from '../components/globals/RelatedProjects';
import DevelopmentsGallery from '../components/slices/pages/DevelopmentsGallery';
import Form from '../components/globals/Form';

export const data = graphql`
  {
    prismicContactForm {
      dataRaw
    }
    prismicContactInformation {
      data {
        contact_information_section {
          section_text {
            html
            text
          }
        }
      }
    }
    allPrismicProjectTemplate {
      nodes {
        data {
          project_title {
            text
            html
          }
          related_image {
            url
          }
        }
        uid
      }
    }
    prismicPageTemplate(uid: { eq: "developments" }) {
      data {
        body {
          ... on PrismicPageTemplateBodyImageGallery {
            id
            items {
              image {
                url
              }
            }
            primary {
              description_of_the_gallery {
                text
                html
              }
              name_of_the_gallery {
                html
                text
              }
              title {
                html
                text
              }
            }
            slice_type
          }
        }
      }
    }
  }
`;

const projects = ({ data }) => {
  const {
    prismicContactForm: {
      dataRaw: { hero_image: image },
    },
    allPrismicProjectTemplate: { nodes: projects },
    prismicPageTemplate: {
      data: { body: slices },
    },
  } = data;

  return (
    <Layout bgColor={color.mainBg}>
      <HeroSection
        contact
        bg={image.url}
        header={`<h1>Projects</h1>`}
        subheader={`<p>A selection of our recent developments</p>`}
      />
      <RelatedProjects items={projects} pages projectPage />

      <DevelopmentsGallery
        title={slices[slices.length - 1].primary.title}
        description={slices[slices.length - 1].primary.description_of_the_gallery.html}
        images={slices[slices.length - 1].items}
        name={slices[slices.length - 1].primary.name_of_the_gallery.html}
      />
      <Form cms={data.prismicContactInformation.data.contact_information_section} />
    </Layout>
  );
};

export default withPreview(projects);
