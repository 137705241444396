import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { pageMargins, typography, color } from '../../shared/styles.js';
import { StyledLink } from '../../shared/StyledButton';
import Container from '../../shared/Container';

const StyledRelatedProjects = styled.div`
  width: 100%;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  & a {
    color: white;
    text-decoration: none;
  }
  & h2 {
    text-align: left;
    font-size: ${typography.size.l1};
    font-family: ${typography.type.primary};
    font-weight: ${typography.type.regular};
    margin-top: 3rem;
  }
  & .project__container {
    width: 100%;
    height: ${({ projectsLength }) => `${(projectsLength / 2) * 23}rem`};
    position: relative;
    display: grid;
    position: relative;
    justify-content: space-between;
    grid-template-columns: calc(50% - 20px) calc(50% - 20px);
    ${({ projectsLength }) =>
      projectsLength > 2 ? 'grid-template-rows: calc(50% - 20px) calc(50% - 20px)' : ''};
    grid-gap: 40px;
    margin-bottom: 4rem;
    margin-top: ${({ projectPage }) => (projectPage ? '3rem' : '0')};
    @media only screen and (max-width: 640px) {
      grid-template-columns: 100%;
      grid-template-rows: ${({ projectsLength }) => `repeat(${projectsLength},1fr)`};
      height: 44rem;
    }
    @media only screen and (min-width: 640px) and (max-width: 1024px) {
      height: ${({ projectsLength }) => `${(projectsLength / 2) * 20}rem`};
    }
    @media only screen and (min-width: 1440px) {
      height: ${({ projectsLength }) => `${(projectsLength / 2) * 30}rem`};
    }
    @media only screen and (max-width: 640px) {
      height: unset;
    }
    & .project__individual {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      overflow: hidden;
      @media only screen and (max-width: 640px) {
        padding: 3.5rem;
      }
      & .project__overlay {
        z-index: 1;
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: ${color.overlay};
        opacity: 0;
        will-change: opacity;
        transition: opacity 0.7s cubic-bezier(0.19, 0.68, 0.08, 0.87);
      }
      &:hover {
        & .project__overlay {
          opacity: 1;
        }
        & img {
          transform: scale(1.05);
        }
      }
      & img {
        z-index: 1;
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        will-change: transform;
        transition: transform 0.7s cubic-bezier(0.19, 0.68, 0.08, 0.87);
      }
      & h2 {
        font-size: ${typography.size.m3};
        font-family: ${typography.type.primary};
        font-weight: ${typography.type.regular};
        z-index: 2;
        position: relative;
        text-align: center;
      }
      @media only screen and (max-width: 640px) {
        & h2 {
          font-size: ${typography.size.m2};
        }
      }
    }
  }
  @media only screen and (max-width: 640px) {
    & > h2 {
      font-size: ${typography.size.m3};
      text-align: center;
      width: 100%;
      margin-top: 1rem;
    }
    & .project__container {
      & .project__individual {
        & .project__overlay {
          opacity: 1;
        }
      }
    }
  }
`;

const RelatedProjects = ({ items, pages, projectPage }) => {
  return (
    <StyledRelatedProjects projectsLength={items.length} projectPage={projectPage}>
      <Container>
        {!projectPage ? <h2>{`${!pages ? 'Related ' : ''}Projects`}</h2> : ''}
        <div className="project__container">
          {items.map(item => (
            <Link to={`/projects/${pages ? item.uid : item.link.uid}`}>
              <div className="project__individual">
                <img
                  src={
                    pages ? `${item.data.related_image.url}&w=1024` : `${item.link.document.data.related_image.url}&w=1024`
                  }
                />
                <div className="project__overlay" />
                <h2>{pages ? item.data.project_title.text : item.title.text}</h2>
                <StyledLink activeColor="white">View Project</StyledLink>
              </div>
            </Link>
          ))}
        </div>
      </Container>
    </StyledRelatedProjects>
  );
};

export default RelatedProjects;
