import React from 'react';
import styled from 'styled-components';
import ImageCarousel from '../../globals/ImageCarousel';
import parse from 'html-react-parser';
import { typography, pageMargins } from '../../../shared/styles';

const StyledDevGallery = styled.div`
  ${pageMargins}
  margin: 4.5rem 0;
  h2 {
    font-size: ${typography.size.l1};
    font-family: ${typography.type.primary};
    text-align: center;
  }

  & .dev-gallery-text__container {
    display: flex;
    flex-direction: column;
    margin-bottom: 4.5rem;

    & h2 {
      font-size: ${typography.size.m2};
      text-align: left;
    }
    & .gallery--description {
      display: grid;
      grid-template-columns: 50% 25% 25%;
      grid-template-rows: 30px 40px 30px auto;
      grid-column-gap: 20px;
      & p {
        font-family: ${typography.type.secondary};
        font-size: 0.9375rem;
        font-weight: ${typography.weight.regular};
        line-height: 1.5rem;
      }
      & h6 {
        font-family: ${typography.type.secondary};
        font-size: 0.75rem;
        font-weight: ${typography.weight.bold};
        line-height: 1.5rem;
        letter-spacing: 0.1rem;
      }
      & > p:nth-child(1) {
        grid-column: 1/2;
        grid-row: 1/5;
        padding-right: 3rem;
      }
      & > p:nth-child(3) {
        grid-column: 2/3;
        grid-row: 2/3;
      }
    }
  }
  & .navigation-container {
    float: right;
  }
  @media only screen and (max-width: 1024px) {
    & .dev-gallery-text__container {
      margin-bottom: 3rem;
    }
  }

  @media only screen and (max-width: 640px) {
    h2 {
      font-size: ${typography.size.l1};
      text-align: left;
      width: 100%;
    }
    & .gallery--name {
      h2 {
        font-size: ${typography.size.m2};
        margin-bottom: 1rem;
      }
    }
    & .dev-gallery-text__container {
      & .gallery--description {
        display: grid;
        grid-template-columns: calc(50% - 20px) calc(50% - 20px);
        grid-template-rows: auto 40px 30px;
        grid-column-gap: 20px;
        & > p:nth-child(1) {
          grid-column: 1/3;
          grid-row: 1/2;
          padding-right: 0rem;
          margin-bottom: 2rem;
        }
        & > p:nth-child(3) {
          grid-column: 1/2;
          grid-row: 3/4;
        }
        p {
          font-size: 1rem;
          line-height: 1.5em;
        }
      }
    }
  }
`;

function renderGalleryTextArea(name, description) {
  return (
    <div className="dev-gallery-text__container">
      <div className="gallery--name">{parse(name)}</div>
      <div className="gallery--description"> {parse(description)}</div>
    </div>
  );
}

const DevelopmentsGallery = ({ images, title, description, name, uid }) => {
  const classTitle = title.text.toLowerCase().replace(/\s/g, '-');
  const isInvestments = uid === 'investments';
  return (
    <StyledDevGallery className={classTitle}>
      {!isInvestments && title && parse(title.html)}
      {!isInvestments && (name || description) ? renderGalleryTextArea(name, description) : ''}
      <ImageCarousel
        name={title.text ? title.text.toLowerCase().replace(/\s/g, '-') : 'no-title'}
        size="l"
        images={images.map(item => item.image)}
        page
      />
    </StyledDevGallery>
  );
};

export default DevelopmentsGallery;
